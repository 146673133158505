.overlay {
    background: yellow;
    padding: 30px;
    border: 4px double black;
}

#subject {
    min-width: 20rem;
    max-width: 24rem;
    background: white;
    padding: 15px 3px;
    border: 7px solid red;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: 1.4rem;


}

#bodyContent{
    min-width: 20rem;
    max-width: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
}

#comp1 {
    min-width: 20rem;
    max-width: 24rem;
    background: white;
    padding: 3px ;
    border: 7px solid blue;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
}

#comp2 {
    min-width: 20rem;
    max-width: 24rem;
    background: white;
    padding:3px ;
    border: 7px solid blue;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
}
/*#subject b{*/
/*    font-size: 1.2rem;*/
/*}*/

#comp3 {
    min-width: 20rem;
    max-width: 24rem;
    background: white;
    padding:3px ;
    border: 7px solid blue;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;

}


/*.med{*/
/*    color:red*/
/*}*/
.big-box-container{
    background-color:rgb(38,77,145) ;
    width:100% ;
    height:auto ;
    padding:0.5% ;
    border-radius:0.6rem;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    color:#FFFFFF;
    font-size:1.2rem ;
    min-height:4.5rem
}
.single-subject-container{
    width:23% ;
    height: 100%;
    display:flex;
    flex-direction: column ;
    align-items:center;
    justify-content:center;
}
.use-lookup-container{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;

}



/*#printme {*/
/*    !*display: none;*!*/
/*}*/
/*@media print {*/
/*    #printme {*/
/*        display: block;*/
/*    }*/
/*}*/
@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        padding: -30px;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: A4;
    margin: 2mm;
}


.preview-pdf-container{
    width: 100%;
    margin: auto;
}

@media (min-width: 1140px) {
    .preview-pdf-container{
        width: 1140px;
    }
}
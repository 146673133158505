.my-table-container{
    width: 100%;
    height: auto;
    border: 1px solid black;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.75rem;
    font-weight: 900;
}

.my-table-row-container{
    width: 100%;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.8rem 0.8rem 0 0;
    color:#FFFFFF;
}

.my-subject-cell{
    width:12%;
    padding: 8px;
    text-align: center;
    height: 100%;
    word-wrap: break-word;
}

.my-comp-cell{
    width: 22%;
    text-align: center;
    padding: 8px;
    height: 100%;
    word-wrap: break-word;
}
.my-table-left-border{
    border-left: 1px solid black;
}


.my-comp-cell-double{
    width: 22%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
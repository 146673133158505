.my-react-easy-crop{
    position: relative !important;
    width: 80%;
    height: 10rem;
    top:5rem !important;
    left:10% !important;


    z-index: 120000;

}